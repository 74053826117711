import React,{memo} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface PickerType {
    rangeStart: Date,
    rangeEnd: Date,
    selectStartDate: (data: Date) => void
    selectEndDate: (date: Date) => void
}

function Picker({ rangeStart, rangeEnd, selectStartDate, selectEndDate }: PickerType) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', zIndex: 0 }}>

            <span style={{ paddingLeft: 3, paddingRight: 3 }}>start date: </span>
            <DatePicker
                selectsStart
                selected={rangeStart}
                startDate={rangeStart}
                endDate={rangeEnd}
                onChange={selectStartDate}
            />

            <span style={{ paddingLeft: 3, paddingRight: 3 }}>end date:  </span>
            <DatePicker
                selectsEnd
                minDate={rangeStart}
                selected={rangeEnd}
                startDate={rangeStart}
                endDate={rangeEnd}
                onChange={selectEndDate}
            />

        </div>
    );
}

export default memo(Picker)


