import React, { useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { login } from '../APIs/APIs.tsx';
import { useNavigate } from 'react-router-dom';

const defaultTheme = createTheme();

export const Login = () => {
    const navigate = useNavigate();
    const inputRefValue = useRef(null);

    useEffect(() => {
        cleanupLocalStorage();
    }, []);

    const cleanupLocalStorage = () => {
        localStorage.removeItem('warehouse');
        localStorage.removeItem('id');
        localStorage.removeItem('token');
        localStorage.removeItem('name');
    };

    const setWarehouseData = (data) => {
        const variablesToCheck = ['center', 'store', 'procurement1', 'procurement2', 'quality'];
        const results = variablesToCheck.filter(variable => data[variable].toLowerCase() === 'yes');
        if (results.length > 0) {
            localStorage.setItem('warehouse', JSON.stringify({ results }));
            localStorage.setItem('id', data.id);
            localStorage.setItem('name', data.name);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const pincode = inputRefValue.current.value
        const data = await login(pincode)
	console.log(data.data)
	console.log(pincode)
        console.log(data);
        localStorage.setItem('token', data.data.accessToken);
        setWarehouseData(data.data);
        navigate('/trackitems');
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="user_id"
                            label="User ID"
                            inputRef={inputRefValue}
                            name="user_id"
                            autoFocus
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};
