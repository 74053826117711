import React, { useState, useEffect } from 'react';
import "./loading.css";

export const Loading = () => {
  const [showContent, setShowContent] = useState(true);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowContent(false);
    }, 20000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className={`spinner-box ${showContent ? 'loaded' : ''}`}>
      {showContent ? (
        <div className="three-quarter-spinner"></div>
      ) : (
        <p style={{textAlign:'center'}}>No data found. Network issue or there might be no data.</p>
      )}
    </div>
  );
};
