import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface FormDialogType {
    handleClickOpen: () => void,
    handleClose: () => void,
    open: boolean,
    handleConfirm: () => void
    handleChangeSecretKey: () => void,
    errorMessage: boolean
}

export default function FormDialog({ handleClickOpen, handleClose, open, handleConfirm, handleChangeSecretKey, errorMessage }: FormDialogType) {


    return (
        <React.Fragment>

            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                        event.preventDefault();
                        const formData = new FormData(event.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        handleClose();
                    },
                }}
            >
                <DialogTitle>Confirm Adding new items to BodyPlus</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        required
                        margin="dense"
                        id="name"
                        name="secretKey"
                        label="Secret Key"
                        type="text"
                        error={errorMessage}
                        // helperText={errorMessage}
                        
                        fullWidth
                        variant="outlined"
                        onChange={handleChangeSecretKey}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={handleConfirm}>Import</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
