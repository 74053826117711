

import React, { useState, useCallback } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import * as XLSX from 'xlsx';
import { importFileAPI, importFileAPIToUpdate } from "../APIs/APIs.tsx"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import FormDialog from '../component/formDialog/formDialog.tsx';
import { useEffect } from 'react';

interface ExcelRow {
    [key: string]: string | number; // Adjust based on your data types
}
export const ImportFile = () => {
    const [excelData, setExcelData] = useState<ExcelRow[] | null>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [open, setOpen] = useState<boolean>(false);
    const [secretKey, setSecretKey] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState<boolean>(false)
    const [trackButton, setTrackButton] = useState<string>('No Value')

    const handleChangeSecretKey = (event) => {
        setSecretKey(event.target.value)
    }

    const handleClickOpen = () => {
        setOpen(true);
    };



    const handleClose = () => {

        setOpen(false);
    };

    const onDrop = (acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = e?.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
            setExcelData(jsonData);
        };

        reader.readAsBinaryString(file);

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const updateToItemTable = useCallback(async (table: string, excelData: [], secretKey) => {
        try {
            const items = await importFileAPIToUpdate(table, excelData, secretKey);
            return items
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    }, [importFileAPI, excelData]);

    const insertToItemTable = useCallback(async (table: string, excelData: [], secretKey) => {
        try {
            const items = await importFileAPI(table, excelData, secretKey);
            return items
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    }, [importFileAPI, excelData]);

    const handleClick = (VALUE: string) => {
        if (excelData?.length === 0 || excelData === null)
            return;
        setTrackButton(VALUE)
        setOpen(true)
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const handleConfirm = async () => {
        let result;
        setOpen(true)
        if (trackButton === 'INSERT')
            result = await insertToItemTable("item", excelData, secretKey)
        else if (trackButton === 'UPDATE')
            result = await updateToItemTable("item", excelData, secretKey)

        console.log(result)
        if (result.status === false) {
            setOpen(true)
            setErrorMessage(true)
        }
        else if (result.status === true) {
            setOpen(false)
            setErrorMessage(false)
        }
        // handle Success Orders
        // if (result.msg === 'Imported Successfull')
        //     setOpen(false)
    }

    const dropzoneStyles = {
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
    };

    return (
        <Container sx={{ marginTop: 10 }}>
            <div {...getRootProps()} style={dropzoneStyles}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop an Excel file here, or click to select one</p>
            </div>
            <Box marginTop={4}>
                <FormDialog handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} handleConfirm={handleConfirm} handleChangeSecretKey={handleChangeSecretKey} errorMessage={errorMessage} />
                <Button variant="contained" color="primary" onClick={() => handleClick('INSERT')}>Import File to Insert</Button>
                <Button sx={{ marginLeft: 2 }} variant="contained" color="secondary" onClick={() => handleClick('UPDATE')}>Import File To Edit</Button>
            </Box>
            {excelData && (
                <div style={{ paddingTop: 5, paddingBottom: 5 }}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {Object.keys(excelData[0]).map((header) => (
                                        <TableCell key={header}>{header}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>

                                {excelData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index): any => (
                                        <TableRow key={index}>
                                            {Object.values(row).map((cell, cellIndex) => (
                                                <TableCell sx={{ maxWidth: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} key={cellIndex}>{cell}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={excelData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </Container>
    );
};




