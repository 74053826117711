// Sidebar.js
import React,{memo} from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Divider } from '@mui/material';

interface SiderbarTypes {
    open:boolean,
    handleClose:() => void
    handleItemsAvailability:(arg0: string,arg1:Number) => void
}

const Sidebar = ({ open, handleClose,handleItemsAvailability }: SiderbarTypes) => {
  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
    >
      <div>
        <IconButton onClick={handleClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <List>
        <ListItem>
          <ListItemText sx={{fontWeight:'bolder'}}>Found Items</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleItemsAvailability('center_feedback',1)}>
          <ListItemText sx={{color:'grey'}} primary="Center" />
        </ListItem>
        <ListItem button onClick={() => handleItemsAvailability('store_feedback',1)}>
          <ListItemText sx={{color:'grey'}} primary="Store" />
        </ListItem>
        <ListItem button onClick={() => handleItemsAvailability('procumenet_feedback',1)}>
          <ListItemText  sx={{color:'grey'}} primary="Procurement" />
        </ListItem>
        
        <Divider />
        <ListItem>
          <ListItemText primary="Not Found Items" />
        </ListItem>
        <Divider />
        <ListItem button onClick={() => handleItemsAvailability('center_feedback',2)}>
          <ListItemText  sx={{color:'grey'}} primary="Center" />
        </ListItem>
        <ListItem button onClick={() => handleItemsAvailability('store_feedback',2)}>
          <ListItemText sx={{color:'grey'}} primary="Store" />
        </ListItem>
        <ListItem button onClick={() => handleItemsAvailability('procumenet_feedback',2)}>
          <ListItemText sx={{color:'grey'}} primary="Procurement" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default memo(Sidebar);
