import React, { useEffect, useCallback, useState } from "react";
import { getReports } from "../APIs/APIs.tsx"
import Container from "@mui/material/Container"
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Picker from "../component/picker/picker.tsx"
import { Box } from "@mui/system";
import { Button } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import DropDown from "../component/menu/menu.tsx"
import { handleOnExport } from "../utility/utility.tsx"

const columns = [

    { id: "parent_id", label: "parent_id", minWidth: 100 },
    { id: "brand", label: "brand", minWidth: 100 },
    { id: "category", label: "category", minWidth: 100 },
    { id: "sku", label: "sku", minWidth: 100 },
    {
        id: "title",
        label: "title",
        minWidth: 170,
        align: "right",

    },
    {
        id: "color",
        label: "color",
        minWidth: 170,
        align: "right",
        format: value => value.toLocaleString()
    },
    {
        id: "size",
        label: "size",
        minWidth: 170,
        align: "right",
        format: value => value.toFixed(2)
    },
    {
        id: "total_quantity",
        label: "total quantity",
        minWidth: 170,
        align: "right",
        format: value => value.toFixed(2)
    },
    {
        id: "last_date_requested",
        label: "last date requested",
        minWidth: 170,
        align: "right",
        format: value => value.toFixed(2)
    }
];

export function Report() {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState<number>(5);
    const [startDate, setStartDate] = React.useState('');
    const [endDate, setEndDate] = React.useState('');
    const [rangeStart, setRangeStart] = React.useState(new Date());
    const [rangeEnd, setRangeEnd] = React.useState(new Date());
    const [rows, setRows] = useState([])
    const [report_type, serReportType] = React.useState<string>();

    const handleChangeDropDownMenu = (event: SelectChangeEvent<typeof report_type>) => {
        serReportType(event.target.value)
    };

    const selectStartDate = (d: Date) => {
        const formattedDate = d.toISOString().split("T")[0];
        console.log(formattedDate)
        setStartDate(formattedDate)
        setRangeStart(d);
    };

    const selectEndDate = (d: Date) => {
        console.log(d)
        const formattedDate = d.toISOString().split("T")[0];
        console.log(formattedDate)
        setEndDate(formattedDate)
        setRangeEnd(d);
    };

    const RetreiveReports = useCallback(async (reportType: number, dateRange: string[]) => {
        console.log({
            reportType,
            dateRange
        })
        try {
            const items = await getReports(reportType, dateRange);
            setRows(items.data);
        } catch (error) {
            console.error('Error fetching reports:', error);
        }
    }, [getReports, setRows]);

    useEffect(() => {
        const formatDate = (date: Date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        const currentDate = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
        const currentDateFormat = formatDate(currentDate);
        const sevenDaysAgoFormat = formatDate(sevenDaysAgo);


        RetreiveReports(2, [sevenDaysAgoFormat, currentDateFormat]); // second option which is the default
    }, [RetreiveReports]);



    // useEffect(() => {
    //     RetreiveReports(2, ["2024-01-01", "2024-01-10"]);
    // }, [RetreiveReports]);


    const handleChangePage = (event: { target: any; }) => {
        let targetElement = event.target
        const testIdValue = targetElement.getAttribute('data-testid');
        let p = testIdValue && testIdValue.toString() === 'KeyboardArrowRightIcon' ? page + 1 : page - 1
        setPage(p);
    };

    const handleChangeRowsPerPage = (event: { target: { value: string | number; }; }) => {
        console.log(event.target.value)
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleClick = () => {
        if (startDate === '' || endDate === '')
            return alert('something wrong with date picker')
        let dateRange = [startDate, endDate]
        let report: string;
        console.log(report_type)
        if (!report_type)
            report = "2"
        else report = report_type
        console.log(report)

        RetreiveReports(parseInt(report), dateRange);
    }

    return (
        <Container maxWidth="lg">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, marginTop: 10 }}>
                <DropDown report_type={report_type} handleChange={handleChangeDropDownMenu} />
                <Picker selectStartDate={selectStartDate} selectEndDate={selectEndDate} rangeStart={rangeStart} rangeEnd={rangeEnd} />
                <Button variant="contained" onClick={handleClick}>Search</Button>
            </Box>

            <Box sx={{ marginTop: 5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button variant="contained" color="secondary" onClick={() => handleOnExport(rows)}>Export</Button>
            </Box>

            <Paper sx={{ marginTop: '5px', zIndex: 1, overflowX: 'auto', paddingTop: 5 }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {columns.map(column => (
                                    <TableCell key={column.id} style={{ minWidth: column.minWidth, backgroundColor: '#f0f0f0', fontWeight: 'bold' }}>
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows && rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => (
                                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                        {columns.map(column => (
                                            <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                                                {column.format && typeof row[column.id] === 'number'
                                                    ? column.format(row[column.id])
                                                    : row[column.id]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20, 25, 50, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </Container>
    );
}
