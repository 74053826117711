import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import FormLabel from "@mui/material/FormLabel";
import LinearProgress from "@mui/material/LinearProgress";

export default function ProgressBar({
  items_count,
  center_feedback_yes_count,
  store_feedback_yes_count,
  procurement_feedback_yes_count,
}) {
  const [centerFeedbackPercentage, setCenterFeedbackPercentage] = useState(0);
  const [storeFeedbackPercentage, setStoreFeedbackPercentage] = useState(0);
  const [procurementFeedbackPercentage, setProcurementFeedbackPercentage] = useState(0);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (items_count > 0) {
      const centerPercentage = (center_feedback_yes_count / items_count) * 100;
      const storePercentage = (store_feedback_yes_count / items_count) * 100;
      const procurementPercentage = (procurement_feedback_yes_count / items_count) * 100;

      setCenterFeedbackPercentage(centerPercentage);
      setStoreFeedbackPercentage(storePercentage);
      setProcurementFeedbackPercentage(procurementPercentage);

      const progressPercentage = centerPercentage + storePercentage + procurementPercentage;
      setProgress(progressPercentage);
    }
  }, [items_count, center_feedback_yes_count, store_feedback_yes_count, procurement_feedback_yes_count]);

  return (
    <>
      <Stack sx={{ width: "100%" }}>
        <FormLabel >
          <span style={{ width: '20px', height: '20px', backgroundColor: 'rgb(0,255,0)', color: 'rgb(0,255,0)', margin: '5px' }}>.
          </span>
          {center_feedback_yes_count}
          <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
          <span style={{ width: '20px', height: '20px', backgroundColor: 'rgb(255,255,0)', color: 'rgb(255,255,0)', margin: '5px' }}>.
          </span>
          {store_feedback_yes_count}

          <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
          <span style={{ width: '20px', height: '20px', backgroundColor: 'rgb(255,0,0)', color: 'rgb(255,0,0)', margin: '5px' }}>.
          </span>
          {procurement_feedback_yes_count}

          <span style={{ marginLeft: 5, marginRight: 5 }}>-</span>
          <span>out of  </span>
          {items_count}


        </FormLabel>
        {/* <FormLabel>{progress.toFixed(2)}%</FormLabel> */}
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{

            background: center_feedback_yes_count === 0 && store_feedback_yes_count === 0 && procurement_feedback_yes_count === 0 ? `rgb(0,0,0)` : `linear-gradient(to right,
              rgb(0,255,0) 0%,
              rgb(0,255,0) ${centerFeedbackPercentage}%,
              rgb(255,255,0) ${centerFeedbackPercentage}%,
              rgb(255,255,0) ${centerFeedbackPercentage + storeFeedbackPercentage}%,
              rgb(255,0,0) ${centerFeedbackPercentage + storeFeedbackPercentage}%,
              rgb(255,0,0) ${centerFeedbackPercentage + storeFeedbackPercentage + procurementFeedbackPercentage}%,
              rgb(0,0,0) 100%
            )`,
            "& .MuiLinearProgress-bar": {
              background: "transparent",
            },
          }}
        />
      </Stack>
    </>
  );
}
