import React from 'react'
import Nav from '../nav/nav.tsx';

interface MainTypes {
    children: React.ReactNode;
}
export const Main = ({ children }: MainTypes) => {

    return (
        <div>
            <Nav />
            <div>{children}</div>
        </div>
    )
}