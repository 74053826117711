import React, { SetStateAction, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Container, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import  ItemGrid  from './item/item.tsx';
import { getBasaketItems, updateBasket, updateItem, updateOrder, insertToBasketStatus } from '../APIs/APIs.tsx';
import { handlePrintLabel, getcurrentStage } from '../utility/utility.tsx';
import { Item } from '../typs.ts';


type FullScreenDialogItem = {
    id: number,
    open: boolean,
    order_no: number,
    order_id: number,
    handleClick: (id?: number) => void
};

export default function FullScreenDialog({ id, open, order_no, order_id, handleClick }: FullScreenDialogItem) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [item, setItems] = useState<Item[] | undefined>();
    const warehouse = getcurrentStage()
    const matches: boolean = useMediaQuery('(min-width:300px)');
    const [sku, setSku] = useState<any>(undefined)
    const [updated_items, setupdatedItems] = useState<Item[] | undefined>();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSku(event.target.value as unknown as SetStateAction<string | undefined>)
    }

    const handleClick1 = async (value: Item, sku1: string, ref: number) => {
        const array = sku1.split(",").map((item) => item.trim());
        let backgroundColor = '';
        let id = value.id
        let newStatus: string;
        if (ref === 1) {
            newStatus = "Not Found";
            backgroundColor = "#F08080";
        } else if (array.includes(sku)) {
            newStatus = "found";
            backgroundColor = "#ACE1AF";
        } else {
            newStatus = "sku-not-matched";
        }
        const updatedItem = { ...value, status: newStatus, background: backgroundColor };
        setItems((prevItems) => {
            return prevItems?.map((item) =>
                item.sku === updatedItem.sku && item.id === id ? updatedItem : item
            );
        });

        if (newStatus === "sku-not-matched") {
            return;
        }

        let stage = warehouse
        const toWhereToAnswer = stage === "center" ? "center_feedback" : stage === "store" ? "store_feedback" : stage === "procurement" ? "procumenet_feedback" : "quality_feedback"
        if (toWhereToAnswer === "quality_feedback")
            trackCompleteditems()
        const state = newStatus === "found" ? "Yes" : newStatus === "Not Found" ? "No" : "sku-not-matched"
        const user_id = localStorage.getItem('id')
        const UpdatefromWhere = 'dialog'
        if (user_id) {
            let updatedItemsvalue = await updateItem(value.id, toWhereToAnswer, state, user_id, stage, value.basket_id,UpdatefromWhere)
            console.log(updatedItemsvalue)
            let itemsbelogorder = updatedItemsvalue?.filter((item: Item) => item.order_id === value.order_id)
            console.log('--------items--------')
            console.log(itemsbelogorder)
            if (itemsbelogorder && itemsbelogorder.length > 0) {
                let updateBasket = await insertToBasketStatus(itemsbelogorder)
            }
            if (updatedItemsvalue !== null)
                setupdatedItems(updatedItemsvalue)
        }
    }

    const handleControlDialog = () => {
        handleClick();
    };

    const trackCompleteditems = () => {
        return updated_items?.every((item) => item.quality_feedback === "Yes" || item.quality_feedback === "No");
    }

    useEffect(() => {
        if (id !== 0)
            fetchData()
    }, [id])
    useEffect(() => {
        setItems([])
        setupdatedItems([])
    }, [open])

    const fetchData = async () => {
        let basketItems = await getBasaketItems(id, order_no.toString())
        setupdatedItems([])
        const timer = setTimeout(() => {
            setItems(basketItems)
        }, 1000);
        return () => clearTimeout(timer);
    }

    const handleSaveChanges = async () => {
        let status = "completed";
        let work_order_id = item?.[0]?.work_order_id;
        let basket_id = id;
        if (work_order_id && basket_id) {
            let basketUpdateResult: string | undefined | null;
            let orderUpdate: string | null;
            let insertToBasket: string | undefined | null;
            try {
                basketUpdateResult = await updateBasket(basket_id, 'available');
                insertToBasket = await insertToBasketStatus(updated_items)
                orderUpdate = await updateOrder([order_no], 'completed from quality')
                if (basketUpdateResult === "success") {
                    handleClick();
                    return "success";
                }
            } catch (error) {
                alert('something went wrong')
            }
        }
    }

    const items = item?.length === 0 ? [] : item
    const order_stage_status = items?.map((item) => item.order_stage)[0]?.toString() || '';
    const disabled = updated_items?.length === 0 || order_stage_status === 'completed from quality' ? false : trackCompleteditems()

    return (
        <div>
            {items && items.length > 0 && <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={handleControlDialog}
                aria-labelledby="responsive-dialog-title"
                scroll="paper"
            >
                <DialogTitle id="responsive-dialog-title">
                    {"No Of Items"} - {items.length}
                </DialogTitle>
                <DialogContent>
                    <Container maxWidth={false} style={{ background: '#F8F8F8', marginTop: 10, paddingBottom: 20 }}  >
                        {items && items.length > 0 &&
                            <Grid container justifyContent="center" alignItems="center" style={{ gap: 70 }}>
                                <Grid container style={matches ? { columnGap: 10, rowGap: 20, display: 'flex', justifyContent: 'center' } : { gap: 22 }}>
                                    {items && items?.map((value: any,index:number) => {
                                        return <ItemGrid key={index} value={{ ...value, background: value.background }} handleClick={handleClick1} handlePrintLabel={handlePrintLabel} handleChange={handleChange} warehouse1={warehouse} />
                                    })}
                                </Grid>
                                {/* <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button> */}
                            </Grid>
                        }
                    </Container>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleControlDialog}>
                        Close
                    </Button>
                    {warehouse === "quality" && <Button onClick={handleSaveChanges} disabled={!disabled} autoFocus>
                        {order_stage_status === 'completed from quality' ? "Already Completed" : "Completed"}
                    </Button>}
                </DialogActions>
            </Dialog>}
        </div>
    );

}