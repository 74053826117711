import axios from 'axios';
import jsPDF from 'jspdf';
import * as XLSX from "xlsx";
import { Item } from '../typs'

export const getToken = () => {
    const result = localStorage.getItem('token')
    if (result === null)
        return;
    return result;
}

const token = getToken()

export const handlePrintLabel = async (value: any, type: string) => {
    //--------- trace data
    let basket_id: string = ''
    let order_id: number = 0
    let name: string = ''
    //---------  basket data
    let order_no: string = ''
    if (type === 'trace') {
        basket_id = value.basket_id.toString();
        order_id = value.order_id;
        name = value.name;
    } else if (type === 'basket')
        order_no = value
    const barcodeValue = type === 'trace' ? `${basket_id} ${order_id} ${name}` : order_no;
    const doc = new jsPDF({
        unit: 'mm',
        format: [70, 30],
    });
    const barcodeImageUrl = `https://www.bodyplus-portal.com/api/barcode?value=${barcodeValue}`;
    try {
        const response = await axios({
            method: 'GET',
            url: barcodeImageUrl,
            headers: { 'Authorization': `Bearer ${token}` },
            responseType: 'arraybuffer'
        });

        if (response.status === 200) {
            const url = URL.createObjectURL(new Blob([response.data]));
            const img = new Image();
            img.src = url;
            img.onload = function () {
                const barcodeWidth = 50;
                const barcodeHeight = 50;
                const estimatedPageSize = [barcodeWidth + 20, barcodeHeight + 20];

                const adjustedDoc = new jsPDF({
                    unit: 'mm',
                    format: estimatedPageSize,
                });

                adjustedDoc.addImage(img, 'JPEG', 10, 10, barcodeWidth, barcodeHeight);
                adjustedDoc.text(barcodeValue, 12, 65)
                const pdfDataUri = adjustedDoc.output('datauristring');
                window.open(pdfDataUri);
                // adjustedDoc.save('barcode.pdf');
            };
        } else {
            console.error('Error generating barcode:', response.statusText);
        }
    } catch (error) {
        console.error('Network error:', error);
    }
};


export const getcurrentStage = () => {
    const result = localStorage.getItem('warehouse')
    if (result === null) {
        return;
    }
    let warehouse = JSON.parse(result).results[0]
    if (warehouse === "procurement1")
        return "procurement"
    return warehouse
}

export const handleOnExport = (data:[]) => {
    console.log('print')
    let wb = XLSX.utils.book_new()
    let ws = XLSX.utils.json_to_sheet(data)
    XLSX.utils.book_append_sheet(wb,ws,"Sheet 1");
    XLSX.writeFile(wb,"Transfers.xlsx");
    console.log('finish')
}