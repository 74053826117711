import React from 'react'
import {
  Route,
  Routes
} from "react-router-dom";
import { Login } from './pages/login';
import { Main } from './component/main/main.tsx';
import { TraceItems } from './pages/trace.tsx';
import { Report } from './pages/report.tsx';
import {ImportFile} from './pages/import.tsx';
import { SimpleTab } from './component/tab/tab.tsx';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import './App.css';


function App() {
  const theme = createTheme();
  const navigate = useNavigate();
  const isLoggedIn = localStorage.getItem('id') ? true : false;

  useEffect(() => {
    if (isLoggedIn) {
      return navigate("/trackitems");
    }
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/trackitems" element={
          <Main>
            <TraceItems />
          </Main>
        } />
        <Route path="/basket" element={
          <Main>
            <SimpleTab />
          </Main>
        } />
        <Route path="/login" element={<Login />} />
        <Route path="/reports" element={
          <Main>
            <Report />
          </Main>
        }>
        </Route>
        <Route path="/file-import" element={
          <Main>
            <ImportFile />
          </Main>
        }>

        </Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;