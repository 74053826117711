import React from 'react'
import { Box, Grid, MenuItem, Switch, TextField } from "@mui/material"

type BasketItem = {
    RealValue: string;
    Value: string;
};

type inputField = {
    checked: boolean,
    handleSwitch: () => void,
    handleSearchbybasket: (e: React.ChangeEvent<HTMLInputElement>) => void,
    handleSelect: (e: React.ChangeEvent<HTMLInputElement>) => void,
    basketStatus: BasketItem[],
    basketStatusState:string
}
export const InputType = ({ checked, handleSwitch, handleSearchbybasket, basketStatus, handleSelect,basketStatusState }: inputField) => {
    return (
        <Box>
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>order no</Grid>
                < Grid item>
                    <Switch
                        checked={checked}
                        onChange={handleSwitch}
                        value="checked"
                    />
                </Grid>
                <Grid item>basket no</Grid>
            </Grid>
            <Grid container gap={1} justifyContent={'center'}>
                <Grid item lg={5} sm={5} xs={12} sx={{ paddingY: 2 }}>
                    <TextField
                        onChange={handleSearchbybasket}
                        id="search"
                        label="search by order number or basket number"
                        variant="outlined"
                        sx={{ width: '100%' }}
                    />
                </Grid>
                <Grid item lg={5} sm={5} xs={12} sx={{ paddingY: 2 }}>
                    <TextField
                        onChange={handleSelect}
                        id="search"
                        select
                        label="search by basket status"
                        variant="outlined"
                        sx={{ width: '100%' }}
                        value={basketStatusState} // Provide a value here
                    >
                        {basketStatus.map((option) => (
                            <MenuItem key={option.RealValue} value={option.RealValue}>
                                {option.Value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Box>
    )
}