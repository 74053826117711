import React , {memo} from "react"
import { Box, Button, Card, CardActions, CardContent, CardMedia, Grid, TextField, Typography, styled, useMediaQuery } from '@mui/material';
import Search from "./../search/search.tsx";
import { Outlet } from 'react-router-dom'
import { Item } from '../../typs'
import {getcurrentStage} from '../../utility/utility.tsx'

const GridItem = styled('div')(({ theme }) => {
    const isLG = useMediaQuery(theme.breakpoints.up('lg'));
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));
    return {
        minWidth: isLG ? '15%' : isMD ? '40%' : isSM ? '90%' : '20%',
        gap: 9,
        position: 'relative',
        width: isLG ? '285px' : isMD ? '285px' : isSM ? '90%' : '385px',
        minHeight: 595,
    };
});

type ItemGridType = {
    handlePrintLabel: (value: Item,type:string) => void,
    handleClick: (value: Item, sku: string, no: number) => void,
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    warehouse1: string
};

interface ItemGridProps {
    value: Item;
    handlePrintLabel: ItemGridType['handlePrintLabel'];
    handleClick: ItemGridType['handleClick'];
    handleChange: ItemGridType['handleChange'];
    warehouse1: ItemGridType['warehouse1'];
}

const ItemGrid: React.FC<ItemGridProps> = ({ value, handlePrintLabel, handleClick, handleChange, warehouse1 }) => {
    const current_stage = getcurrentStage()
    const { order_id, basket_id, location, sku, title, color, size, status, id, background, center_feedback, store_feedback, procumenet_feedback, spf } = value
    if (!value) {
        return null;
    }
    const image = value.image.split(',')[0]   
    const computedBackground =
	 center_feedback === 'Yes' ? 'white' :
            store_feedback === 'Yes' ? 'pink' :
                procumenet_feedback === 'Yes' ? 'green' :
                    'transparent';
    return (
        <GridItem >
            <Grid container justifyContent="center" >
                <Card sx={{ minHeight: 640, background: background }}>
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <Typography sx={{ fontWeight: "bold", backgroundColor: 'rgb(106, 90, 205)', paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white' }} component="h3">{order_id}</Typography>
                        <Typography sx={{ fontWeight: "bold", backgroundColor: 'red', paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white' }} component="h3">{basket_id}</Typography>
                    </Box>
                    <Box sx={{ position: 'relative' }}>
                        <CardMedia
                            component="img"
                            alt={id.toString()}
                            height="140"
                            loading="lazy"
                            image={`${"https://bodyplus-com-sa.b-cdn.net/item_images/" + image + "?width=220&quality=40"}`}
                            style={{ minHeight: 245, height: 300 }}
                        />
                        <span style={{ position: 'absolute', top: 30, left: 0, fontWeight: "bold", backgroundColor: 'blue', paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white' }}>{location}</span>
                        <span style={{ position: 'absolute', top: 30, right: 0, fontWeight: "bold", backgroundColor: 'blue', paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white', height: '23px', width: '47px', background: computedBackground, textAlign: 'center'}}>
                            {store_feedback === 'Yes' ? 'Store' : procumenet_feedback === 'Yes' ? 'Proc' : ''}
                        </span>
                        {spf === "0" && current_stage === "store" ? (<span style={{ position: 'absolute', top: 60,right:0, fontWeight: "bold",  paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white', height: '23px', width: '170px', backgroundColor: 'grey', textAlign: 'center', }}>
                            Transfer + Check
                        </span>) : <></>}
                        {spf === "1" && (current_stage === "center" || current_stage === "quality") ? (<span style={{ position: 'absolute', top: 60,right:0, fontWeight: "bold",  paddingTop: 1, paddingBottom: 1, paddingLeft: 2, paddingRight: 2, color: 'white', height: '23px', width: '70px', backgroundColor: 'grey', textAlign: 'center' }}>
                            Check
                        </span>) : <></>}
                    </Box>
                    <CardContent>
                        <Typography gutterBottom component="h5" style={{ fontSize: '14px' }}>
                            sku: {sku.split(",")[0]}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            title: {title}
                        </Typography>
                        {color === "" ? <></> : <Typography variant="body2" color="text.secondary">
                            color:  {color}
                        </Typography>}
                        {size === "" ? <></> : <Typography variant="body2" color="text.secondary">
                            size:  {size}
                        </Typography>}
                    </CardContent>
                    <CardActions>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '7px' }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", paddingX: 1, gap: 3 }}>
                                <Search handleChange={handleChange} />
                                <Button onClick={() => handleClick(value, sku, 0)} style={{ background: "#1976d2", color: "white" }}>Scan</Button>
                            </Box>
                            {warehouse1 === "store" &&
                                <Button fullWidth style={{ backgroundColor: "#9932CC", color: "white" }} onClick={() => handlePrintLabel(value,'trace')}  >
                                    Print Label
                                </Button>}
                            <Button fullWidth style={{ backgroundColor: "#1976d2", color: "white" }} onClick={() => handleClick(value, sku, 1)}  >
                                Click here if NOT FOUND
                            </Button>
                            <Button fullWidth style={status === "Pending" ? { backgroundColor: "grey", color: "white" } : status === "found" ? { backgroundColor: "green", color: "white" } : status === "Not Found" ? { backgroundColor: "red", color: "white" } : { backgroundColor: "grey", color: "white" }} disabled   >
                                Status: {status === "Pending" ? <span>Pending</span> : status === "found" ? <span>Found</span> : status === "Not Found" ? <span>Not Found</span> : <span>SKU is not matched</span>}
                            </Button>
                        </Box>

                    </CardActions>
                </Card>
            </Grid>
            <Outlet />
        </GridItem>
    )
}

export default ItemGrid
