import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Button from "@mui/material/Button";
import { useNavigate } from 'react-router-dom'
import { CssBaseline, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material";
import { getcurrentStage } from "../../utility/utility.tsx";


const pagesCenter = [
  { name: "Work Order", route: "/trackitems" },
  { name: "Basket", route: "/basket" },
  // { name: "Reports", route: "/reports" },
  // { name: "Files", route: "/file-import" }
];

const pages = [
  { name: "Work Order", route: "/trackitems" },
  { name: "Basket", route: "/basket" },
  { name: "Reports", route: "/reports" },
  { name: "Files", route: "/file-import" }
];

const current_location = getcurrentStage()
function Navbar() {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const name = localStorage.getItem('name')
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<any>, route: string) => {
    const { name } = event.currentTarget
    if (name === "Logout" || route === '/login') {
      localStorage.removeItem('warehouse')
      localStorage.removeItem('id')
      localStorage.removeItem('token')
    }
    navigate(route)
  }
  const handleLogout = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }
  const handleClose = () => {
    setAnchorEl(null);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="static" sx={{ paddingX: 3 }}>
        <CssBaseline />
        <Toolbar disableGutters >
          <Box >
            {current_location === 'center' && pagesCenter.map((page, index) => (
              <Button
                key={index}
                name={page.name}
                sx={{
                  color: 'white',
                  display: 'inline',
                  fontSize: isSmallScreen ? '13px' : '15px',
                  margin: 'auto',
                }}
                onClick={(event) => handleClick(event, page.route)}
              >
                {page.name}
              </Button>
            ))
            }
            {current_location !== 'center' && pages.map((page, index) => (
              <Button
                key={index}
                name={page.name}
                sx={{
                  color: 'white',
                  display: 'inline',
                  fontSize: isSmallScreen ? '13px' : '15px',
                  margin: 'auto',
                }}
                onClick={(event) => handleClick(event, page.route)}
              >
                {page.name}
              </Button>
            ))}
            <Button

              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              sx={{
                color: 'white',
                fontSize: isSmallScreen ? '13px' : '15px',
                margin: 'auto',
                top: isSmallScreen ? 10 : 13,
                position: 'absolute',
                right: 0
              }}
              onClick={handleLogout}

            >
              {name} <KeyboardArrowDownIcon />
            </Button>
            <Menu
              id="basic-menu"

              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}

            >
              <MenuItem disabled>{current_location}</MenuItem>
              <MenuItem onClick={(event) => handleClick(event, '/login')}>logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}


export default Navbar;