import React, { SetStateAction, useEffect, useState, useCallback } from 'react';
import axios from 'axios'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { Button, Container } from '@mui/material';
import { useMediaQuery } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { handlePrintLabel, getcurrentStage, getToken, handleOnExport } from '../utility/utility.tsx';
import ItemGrid from '../component/item/item.tsx';
import { insertToBasketStatus, updateItem, updateWorkOrder } from "../APIs/APIs.tsx"
import { Loading } from '../component/loading/loading.tsx'
import { Item } from '../typs.ts';
import Sidebar from '../component/sidebar/sidebar.tsx';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';



export function TraceItems() {
  const [item, setItems] = useState<Item[] | undefined>();
  const [updated_date, set_updated_date] = useState<Item[] | undefined>();
  const [updated_items, setupdatedItems] = useState<Item[] | undefined>();
  const [sku, setSku] = useState<any>();
  const [open, setOpen] = useState<Boolean>(false);
  const matches: boolean = useMediaQuery('(min-width:300px)');
  const navigate = useNavigate();
  const warehouse1 = getcurrentStage()
  const token = getToken()
  const isLoggedIn = localStorage.getItem('id') ? true : false;
  const location = getcurrentStage()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!isLoggedIn) {
      return navigate('/login');
    }
    fetchData(warehouse1);
  }, []);

  const fetchData = useCallback(
    async (stage: string) => {
      try {
        await axios({
          method: 'POST',
          url: 'https://www.bodyplus-portal.com/api/items',
          data: {
            "id": localStorage.getItem('id'),
            stage: stage
          },
          headers: { 'Authorization': `Bearer ${token}` }
        }).then(response => {
          const toWhereToAnswer = stage === "center" ? "center_feedback" : stage === "store" ? "store_feedback" : stage === "procurement" ? "procumenet_feedback" : "quality_feedback"
          let data: Item[]
          if (stage === "center" || stage === "store")
            data = response.data.data2.results2;
          else {
            const results1 = response.data.data2.results1 || [];
            const results2 = response.data.data2.results2 || [];
            if (results1.length !== 0 && results2.length !== 0) {
              const mergedData = Array.from([...results1, ...results2])
              // const mergedData = [...results1, ...results2];
              data = mergedData.filter((item, index, self) =>
                index === self.findIndex((t) => t.id === item.id)
              );
            } else {
              data = results1.length !== 0 ? results1 : results2;
            }
          }
          if (data.length === 0)
            return;
          const updatedDate = data && data.map((item: Item) => ({
            ...item,
            status: toWhereToAnswer === "quality_feedback" ?
              (
                item.quality_feedback !== null ?
                  (
                    item.quality_feedback === "Yes" ? "found" :
                      item.quality_feedback === "No" ? "Not Found" : "Pending"
                  )
                  :
                  (
                    item.feedback_status === "Yes" ? "found" :
                      item.feedback_status === "No" ? "Not Found" : "Pending"
                  )
              )
              : toWhereToAnswer === "center_feedback"
                ? item.center_feedback === "Yes"
                  ? "found"
                  : item.center_feedback === "No" ? "Not Found" : "Pending"
                : toWhereToAnswer === "store_feedback"
                  ? item.store_feedback === "Yes"
                    ? "found"
                    : item.store_feedback === "No" ? "Not Found" : "Pending"
                  : toWhereToAnswer === "procumenet_feedback"
                    ? item.procumenet_feedback === "Yes"
                      ? "found"
                      : item.procumenet_feedback === "No" ? "Not Found" : "Pending"
                    : "Pending"
          }))

          setItems(updatedDate);
          set_updated_date(updatedDate)

          return () => clearTimeout(timer);
        })
      } catch (error) {
      }
    },
    [item]
  );


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSku(event.target.value as unknown as SetStateAction<string | undefined>)
  }

  const handleClick = async (value: Item, sku1: string, ref: number) => {
    const array = sku1.split(",").map((item) => item.trim());
    let id = value.id;
    let newStatus: string;
    if (ref === 1) {
      newStatus = "Not Found";
    } else if (array.includes(sku)) {
      newStatus = "found";
    } else {
      newStatus = "sku-not-matched";
    }

    if (value.status !== newStatus) {
      const updatedItem = { ...value, status: newStatus };
      setItems((prevItems) => {
        return prevItems?.map((item) =>
          item.sku === updatedItem.sku && item.id === id ? updatedItem : item
        );
      });
    }
    if (newStatus === "sku-not-matched") {
      return;
    }
    let stage = warehouse1
    const toWhereToAnswer = stage === "quality" ? "quality_feedback"
      : stage === "center" ? "center_feedback" : stage === "store" ? "store_feedback" : "procumenet_feedback"
    const state = newStatus === "found" ? "Yes" : newStatus === "Not Found" ? "No" : "sku-not-matched"
    const user_id = localStorage.getItem('id')
    if (user_id) {
      let updatedItemsvalue = await updateItem(value.id, toWhereToAnswer, state, user_id, stage, value.basket_id)
      console.log(updatedItemsvalue)
      let itemsbelogorder = updatedItemsvalue?.filter((item: Item) => item.order_id === value.order_id)
      if (itemsbelogorder && itemsbelogorder.length > 0) {
        let updateBasket = await insertToBasketStatus(itemsbelogorder)
      }
      if (updatedItemsvalue !== null && (stage === 'store' || stage === 'procurement')) {
        setupdatedItems(updatedItemsvalue)
      }
    }
  }

  const handleItemsAvailability = (checkType: string, GroupNo: Number) => {
    console.log(GroupNo)
    console.log(checkType)
    if (GroupNo === 1) {
      set_updated_date(() => {
        return item?.filter((item: any) => {
          if (checkType === 'center_feedback')
            return item.center_feedback === 'Yes'
          else if (checkType === 'store_feedback')
            return item.store_feedback === 'Yes'
          else return item.procumenet_feedback === 'Yes'
        })
      })
    } else {
      set_updated_date(() => {
        return item?.filter((item: any) => {
          if (checkType === 'center_feedback')
            return item.center_feedback === 'No'
          else if (checkType === 'store_feedback')
            return item.store_feedback === 'No'
          else return item.procumenet_feedback === 'No'
        })
      })
    }
  }

  const handleSaveChanges = async () => {
    if (!item) return;
    let location = warehouse1;
    const itemfilter = item;
    const notMatchedItems = itemfilter?.filter((item) => item.status === "sku-not-matched" || item.status === "Pending");
    const foundItems = itemfilter?.filter((item) => item.status === "found");
    if (notMatchedItems && notMatchedItems.length > 0 && location === "center" || location === "quality") {
      alert('One or some items are not matched! Please check the sku and try again');
      return;
    }
    const resultFlag = foundItems?.length === itemfilter?.length;
    let work_order_status: any;
    let basket_status: any;
    let stage: string | undefined;
    if (location === "center") {
      stage = resultFlag ? "quality" : "store";
      work_order_status = await updateWorkOrder(undefined, itemfilter[0].work_order_id, stage);
      // basket_status = await insertToBasketStatus(updated_items);
    } else if (location === "store" || location === "procurement") {
      if (location === "store") {
        stage = resultFlag ? "quality" : "procurement";
        console.log(updated_items)
        let ItemsNeedTransfer = updated_items?.filter((item: Item) => item.spf === "0" && item.store_feedback === "Yes" && item.work_order_stage === "store").map((element) => ({ sku: element.sku, title: element.title, color: element.color, size: element.size }))
        const skuCountMap = {};
        if (ItemsNeedTransfer && ItemsNeedTransfer?.length > 0) {
          ItemsNeedTransfer?.forEach((item) => {
            const { sku } = item;
            skuCountMap[sku] = (skuCountMap[sku] || 0) + 1;
          });
          const skuWithQuantity = Object.entries(skuCountMap).map(([sku, quantity]) => (
            {
              sku,
              title: ItemsNeedTransfer?.find((item) => item.sku === sku)?.title,
              color: ItemsNeedTransfer?.find((item) => item.sku === sku)?.color,
              size: ItemsNeedTransfer?.find((item) => item.sku === sku)?.size,
              quantity
            }
          ));
          handleOnExport(skuWithQuantity)
        }
      }

      if (location === "procurement")
        stage = "quality"

      const update_items = updated_items?.filter((item) => item.store_feedback !== null || item.procumenet_feedback !== null || (item.center_feedback === 'No' && item.store_feedback == null && item.procumenet_feedback == null))
      const final_items = update_items?.map((item: Item) => ({
        ...item,
        current_stage: location
      }))
      console.log(final_items)
      work_order_status = await updateWorkOrder(final_items, undefined, stage);
    }
    let message = 'Wait, there are some items not market for one or more work orders'
    if (work_order_status.msg === message)
      return alert(message)

    if (work_order_status.msg === 'No data has been sent to the client')
      return alert('Please make at least one action to an item')

    if (work_order_status.msg === 'No data needed for processing')
      return alert('work order still not transferred');

    if (work_order_status.status === true) {
      navigate("/basket")
    }
  }

  const itemss = item?.length === 0 ? [] : open === false ? item : updated_date?.length !== 0 && open === true ? updated_date : item
  return (
    <>
      <CssBaseline />
      {location === 'quality' ? <>
        <div style={{ position: 'absolute' }}>
          <IconButton
            color="warning"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <Sidebar open={open} handleClose={handleDrawerClose} handleItemsAvailability={handleItemsAvailability} />
      </> : ''}
      <Container maxWidth={false}>
        {!itemss ? <Loading /> : itemss && itemss.length > 0 &&
          <Grid container justifyContent="center" alignItems="center" style={{ gap: 70 }}>
            <Grid container paddingTop={10} style={matches ? { columnGap: 10, rowGap: 20, display: 'flex', justifyContent: 'center' } : { gap: 22 }}>
              {itemss && itemss?.map((value: any, index: number) => (
                <ItemGrid key={index} value={value} handleClick={handleClick} handlePrintLabel={handlePrintLabel} handleChange={handleChange} warehouse1={warehouse1} />
              ))}
            </Grid>
            {
              warehouse1 !== "quality" && <Button variant="contained" onClick={handleSaveChanges}>Save Changes</Button>
            }
          </Grid>
        }
        <Outlet />
      </Container>
    </>
  );

}