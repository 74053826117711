import React,{memo} from 'react'
import { TextField } from '@mui/material'

interface SearchType {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Search = ({handleChange}:SearchType) => {
    return (
        <TextField
            onChange={handleChange}
            id="outlined-sku"
            label="SKU"
            variant="outlined"
        />
    )
}

export default memo(Search)