import  React,{memo} from 'react'; 
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const reports = [
    {
        id: 1,
        title: 'Quot (App) but found in Rayan -> Transfer + Check'
    },
    {
        id: 2,
        title: 'Quot (Rayan) but found in App -> Check'
    },
    {
        id: 3,
        title: 'Quat (Rayan) and found in Rayan'
    },
    {
        id: 4,
        title: 'Quat (Rayan) but found in procurement'
    },
    {
        id: 5,
        title: 'Quat (App) but found in procurement'
    },

];


interface DropDownTypes {
    report_type: string,
    handleChange: () => void
}

function DropDown({ report_type, handleChange }: DropDownTypes) {

    return (
        <div>
            <FormControl sx={{  width: 200, height: 30, backgroundColor: 'white' }}>
                <Select
                    labelId="select-report-type"
                    id="select-menu"
                    value={report_type}
                    onChange={handleChange}
                    defaultValue={2}
                    sx={{height:30}}
                >
                    {reports.map((value) => (
                        <MenuItem
                            key={value.id}
                            value={value.id}
                            
                        // style={getStyles(value.title, report_type, theme)}
                        >
                            {value.title}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
}

export default memo(DropDown)
